<template>
  <a-result
    status="404"
    title="404"
    sub-title="页面不存在"
    style="padding-top: 128px"
  >
<!--    <a-button slot="extra" type="primary">-->
<!--      <router-link :to="{ name: 'Index' }">返回首页</router-link>-->
<!--    </a-button>-->
  </a-result>
</template>

<script>
export default {
  name: 'Error404'
}
</script>
